<template>
  <div>
    <ApplyMain ref="applymain" stepcurrent=5>
      <div slot="content" class="container">
        <div class="m">
          <el-form :model="form" :label-position="labelPosition" :inline-message="true" :rules="rules" size="mini"
            label-width="100px" class="elform" ref="form">
            <div class="mt">
              <h4 class="title">
                <span>选定主营类目</span>
              </h4>
            </div>
            <div class="mc">
              <div>
                <el-form-item label="主营类目" label-width="185px" prop="taxpayerCertType">
                  <el-cascader-panel ref="cascader" v-model="selectecascader" :options="options" :props="props"
                    clearable @change="oncascader">
                  </el-cascader-panel>
                </el-form-item>
              </div>

              <div v-for="(v,i) in selected" :key="i">
                <el-form-item :label="v['certType']+'资质文件'" label-width="185px" prop="taxpayerCertImgUrl">
                  <el-upload class="" v-model="v['certImgUrl']" :headers="myHeaders" list-type="picture-card"
                    :on-remove="handleRemove" :action="uploadUrl" name="file"
                    accept="image/png,image/gif,image/jpg,image/jpeg" :file-list="taxpayerCertImgfileList"
                    :on-success="dynamicPicSuccess.bind(null, {'index':i,'data':v})" :on-error="busError"
                    :on-change="busChange" :show-file-list="false">
                    <img v-if="v['certImgUrl']!=''" :src="v['certImgUrl']" class="avatar">
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <div class="el-upload__tip">图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持png,jpeg,gif格式，最多可上传1张</div>
                </el-form-item>
              </div>
            </div>
            <div class="mt">
              <h4 class="title">
                <span>入驻费用预览</span>
              </h4>
            </div>
            <div class="mc">
              <div class="mc">
                <div>

                  <el-form-item label="入驻资格及费用" label-width="185px" prop="taxpayerCertType">
                    <div class="form-describe-text upload-pop-desc">
                      <span>结果受经营类目范围影响。费用据经营类目计算SOP模式标准资费估算，实际收费以开店付费单为准。</span>
                    </div>
                    <el-table :data="tableData" height="104" border style="width: 100%">
                      <el-table-column prop="usageFee" label="使用费">
                      </el-table-column>
                      <el-table-column prop="deposit" label="保证金">
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                </div>
              </div>
              <div class="btn-group t-r">
                <el-form-item>
                  <el-button @click="onPrev()">上一步</el-button>
                  <el-button class="btn" @click="onsubmitForm('elForm')" type="primary">提交</el-button>
                </el-form-item>
              </div>
            </div>

          </el-form>
        </div>
      </div>
    </ApplyMain>
  </div>
</template>
<script>
import { companygetbusinesschannel } from '@/api/company';
import {
  getcompanycertintoinfo,
  companyintocertinfo,
  companycalusagefee,
  companyintoinfo,
  getcompanyintoinfo,
} from '@/api/user';
import ApplyMain from '@/components/applymain.vue';
import Cookies from 'js-cookie';
import { cityjson } from '@/utils/cityjson.js';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
export default {
  name: 'contact',
  components: {
    ApplyMain,
  },
  data() {
    return {
      tableData: [],
      fee: {},
      taxpayerCertImgfileList: [],
      city: cityjson,
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      taxRegCertImgfileList: [],
      props: { multiple: true, checkStrictly: false, emitPath: true },
      options: [],
      selected: [],
      selected0: {},
      selectecascader: [],
      imgList: {},
      form: {
        id: 0,
      },
      comid: 0,
      rules: {},
    };
  },
  methods: {
    changeLabel(val) {
      // 是否与上次的类型相同
      let changeFlag = false;
      let changeItem = null;
      if (this.options.length == 0) {
        this.value = val;
      } else {
        // 与原数组比对
        this.value.forEach((item) => {
          if (item[0] !== this.options[0][0]) {
            // 一级标签不同
            changeFlag = true;
            changeItem = item;
          } else if (item[1] != this.options[0][1]) {
            // 一级标签相同但是二级标签不同
            changeFlag = true;
            changeItem = item;
          } else if (
            (!item[2] && this.options[0][2]) ||
            (item[2] && !this.options[0][2])
          ) {
            changeFlag = true;
            changeItem = item;
          }
        });
      }
      if (changeFlag) {
        this.value = [];
        this.value.push(changeItem);
      }
      this.options = this.value;
    },
    oncompanygetbusinesschannel() {
      companygetbusinesschannel({ pid: 0 }).then((res) => {
        if (res.data.code === 1) {
          this.channelservicelist = res.data.result;
          let vl = this.channelservicelist.length;
          let i = 1;
          this.channelservicelist.forEach((item) => {
            let o = {
              value: item.id,
              label: item.data_title,
              children: [],
              disabled: false,
            };
            this.options.push(o);
            this.ongetchildcompanygetbusinesschannel(o, vl == i);
            i++;
          });
        }
      });
    },
    ongetchildcompanygetbusinesschannel(o, isfa) {
      companygetbusinesschannel({ pid: o.value }).then((res) => {
        if (res.data.code === 1) {
          res.data.result.forEach((item) => {
            let op = { value: item.id, label: item.data_title };
            o.children.push(op);
            if (isfa) {
              this.ongetcompanycertintoinfo();
            }
          });
        }
      });
    },
    oncascader(item) {
      let labels = this.$refs.cascader.getCheckedNodes();
      let sel0 = {
        companyCertType: '',
        companyCertTypeId: 0,
        acCompanyIntoCertList: [],
        id: this.comid,
      };
      if (item.length != labels.length) {
        labels.splice(0, 1);
      }
      this.selected0 = sel0;
      //this.selected = [];
      let temselected = [];
      if (labels.length > 0) {
        sel0.companyCertType = labels[0].pathLabels[0];
        sel0.companyCertTypeId = item[0][0];
        this.selected0 = sel0;
        for (let i = 0; i < labels.length; i++) {
          let sel = {
            companyIntoId: this.comid,
            certType: labels[i].data.label,
            certTypeId: item[i][1],
            certImg: '',
            certImgUrl: '',
          };
          this.selected.forEach((op) => {
            if(op.certTypeId==item[i][1]){
              sel=op;
            }
          });
          temselected.push(sel);
        }
        this.selected=temselected;
      }
      this.ondisabled(item);
    },
    ondisabled(item) {
      this.oncompanycalusagefee();
      this.options.forEach((op) => {
        op.disabled = false;
      });

      this.options.forEach((op) => {
        if (op.value != item[0][0]) {
          // 一级标签不同
          op.disabled = true;
        }
      });
    },
    onPrev() {
      this.$router.push('/user/apply/business');
    },

    onsubmitForm() {
      let params = this.selected0;
      params.acCompanyIntoCertList = this.selected;
      companyintocertinfo(params).then((res) => {
        if (res.data.code === 1) {
          this.oncompanyintoinfo();
        } else {
          this.$message.error(res.data.message);
        }
      });
      return;
      this.$refs.form.validate((valid) => {
        if (valid) {
        }
      });
    },
    oncompanyintoinfo() {
      let params = {
        platformCalusageFee: this.fee.usageFee,
        securityDeposit: this.fee.deposit,
        id: this.comid,
      };
      companyintoinfo(params).then((res) => {
        if (res.data.code === 1) {
          this.$message.success('提交成功，等待牛豆豆审核！');
          let that=this;
          setTimeout(() => {
             that.$router.push('/index');
          }, 2000);
        } else {
          this.$message.error(res.data.message);
        }
      });
      return;
    },
    ongetcompanycertintoinfo() {
      let params = this.form;
      let that = this;
      getcompanycertintoinfo(params).then((res) => {
        if (res.data.code === 1) {
          this.comid = res.data.result.id;
          this.selected0 = res.data.result;
          let temselectedcas = [];
          this.selected = res.data.result.acCompanyIntoCertList;
          //let op = { value: item.id, label: item.data_title };
          this.selected0.acCompanyIntoCertList.forEach((item2) => {
            let temselectedcas0 = [];
            temselectedcas0.push(this.selected0.companyCertTypeId);
            temselectedcas0.push(item2.certTypeId);
            temselectedcas.push(temselectedcas0);
          });
          this.selectecascader = temselectedcas;
          that.ondisabled(that.selectecascader);
        } else {
          this.$message.error(res.data.message);
        }
      });

      //this.ondisabled(this.selectecascader[0]);
    },
    oncompanycalusagefee() {
      let params = {
        mainCateId: this.selected0.companyCertTypeId,
        subCateId: [],
      };
      //let category=this.selected0.companyCertType+'('+childrencategory+')'
      this.selected0.acCompanyIntoCertList.forEach((item2) => {
        params.subCateId.push(item2.certTypeId);
      });
      companycalusagefee(params).then((res) => {
        if (res.data.code === 1) {
          this.tableData = [];
          this.fee.deposit = res.data.result.deposit;
          this.fee.usageFee = res.data.result.usageFee;
          let da = {
            category: '',
            deposit: res.data.result.deposit + '元',
            usageFee: res.data.result.usageFee + '元/年',
          };
          this.tableData.push(da);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //动态图上传成功
    dynamicPicSuccess(obj, res, file) {
      var v = obj.data;
      //var imgList = this.imgList;
      //var index = obj.index;
      //console.log(index)
      //imgList[index].picUrl = res.result.fileToken;
      // 少于5张图时，自动添加一行
      //if (imgList.length < 5) imgList.push({ content: '', picUrl: '' });
      //console.log(v)
      //let o = { id: v[1], fileToken: res.result.fileToken };
      //this.imgList['id' + v[1]] = o;
      v.certImg = res.result.fileToken;
      v.certImgUrl = res.result.url + '/image/256x';
    },
    busSuccess(response, file, fileList) {
      var that = this;
      this.taxpayerCertImgfileList = fileList;
      if (response.code == 1) {
        this.form.taxpayerCertImg = response.result.fileToken;
        this.form.taxpayerCertImgUrl = response.result.url + '/image/256x';
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      if (fileList.length > 0) {
        this.taxpayerCertImgfileList = [fileList[fileList.length - 1]];
      }
    },
    busSuccesstaxRegCertImg(response, file, fileList) {
      var that = this;
      this.taxRegCertImgfileList = fileList;
      if (response.code == 1) {
        this.form.taxRegCertImg = response.result.fileToken;
        this.form.taxRegCertImgUrl = response.result.url + '/image/256x';
      }
    },
    busRemovetaxRegCertImg() {},
    busErrortaxRegCertImg(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChangetaxRegCertImg(file, fileList) {
      if (fileList.length > 0) {
        this.taxRegCertImgfileList = [fileList[fileList.length - 1]];
      }
    },
  },
  created() {
    this.oncompanygetbusinesschannel();
  },
};
</script>
<style  scoped>
[class*=' el-icon-'],
[class^='el-icon-'] {
  line-height: 20px;
}
.container >>> .el-form-item__content {
  font-size: 12px;
  line-height: 30px;
  color: #666;
}
h4.title {
  font-size: 16px;
  font-family: 'Microsoft Yahei';
  line-height: 180%;
  color: #333333;
  margin-bottom: 5px;
  float: left;
  margin-right: 10px;
}
.container >>> .el-form-item {
  margin-bottom: 8px;
}
.container >>> .el-form-item__label {
  font-size: 12px;
}
.container >>> .el-input__inner {
  height: 30px;
  font-size: 12px;
  padding-left: 10px;
  line-height: 32px;
}
.container >>> .el-input--prefix .el-input__inner {
  padding-left: 30px;
}
.container .form-describe-text {
  margin-left: 185px;
  color: #999;
  top: -9px;
  line-height: 20px;
  margin-top: 9px;
  position: relative;
  font-size: 12px;
}
.container >>> .el-button {
  padding: 8px 20px;
}
.container >>> .btn {
  border: 1px solid #c6e2ff;
  background-color: #31b7f4;
}
.btn:hover,
.btn-normal:hover {
  border: 1px solid;
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.form-error-text {
  margin-left: 185px;
  color: #ff3333;
  /* top: 0px; */
  line-height: 20px;
  margin-top: 5px;
  position: relative;
  font-size: 12px;
}
.container .upload-pop-desc {
  margin-left: 0;
  top: -5px;
}
.container >>> .el-upload--picture-card {
  width: 78px;
  height: 78px;
  line-height: 86px;
}
.container >>> .el-upload--picture-card img {
  width: 100%;
  height: 100%;
}
.container .el-upload__tip {
  margin-top: 0;
  line-height: 20px;
  color: #999;
}
.container >>> .el-form-item__error--inline {
  margin-left: 0;
}
.container >>> .el-table th {
  background-color: #f5f7fa;
  text-align: center;
}
.container >>> .el-table .cell {
  font-size: 12px;
  text-align: center;
}
</style>